/*
*获取平台结算账户信息  http://192.168.0.26:8765/doc.html#/haolv-consumer/t-us-bank-account-controller/platformBankAccountUsingPOST
*/
const __request = require(`./__request/__request_contentType_json`);
const consumer_web_bankAccount_platformBankAccount = (data) => {
    let pParameter = {
        method: 'post',
        urlSuffix: '/consumer/web/bankAccount/platformBankAccount',
        data: data
    };
    return __request(pParameter)
};
export default consumer_web_bankAccount_platformBankAccount