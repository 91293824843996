import OssClient from "yinzhilv-js-sdk/frontend/common-project/lib/system-docking/aliyun/oss/frontend/client";
import consumer_web_bankAccount_platformBankAccount from "@/lib/data-service/haolv-default/consumer_web_bankAccount_platformBankAccount";
import consumer_credit_createRefundPayment from "@/lib/data-service/haolv-default/consumer_credit_createRefundPayment";

export default {
  components: {},
  data() {
    return {
      form: {
        paymentMethods: 1,
        flowNum: "",
        pictureList: [],
      },
      platformInfo: {},
      paymentMethods: [
        { label: 1, text: "银行转账" },
        { label: 2, text: "支付宝转账" },
      ],
      maxSize: 500,
      fileList: [],
      dialogVisible: false,
      dialogImageUrl: "",
    };
  },
  computed: {
    billId() {
      return this.$route.query.billId;
    },
  },
  methods: {
    // 获取平台结算账户信息
    req_detail() {
      consumer_web_bankAccount_platformBankAccount({
        accountType: this.form.paymentMethods,
      }).then((res) => {
        this.platformInfo = res.datas;
      });
    },
    handleChange(file, fileList) {
      const size = file.size / 1024;
      const maxSize = this.maxSize;
      if (size > maxSize) {
        this.$message.warning(`上传图片限制最大${500}KB`);
        return false;
      } else {
        const myUpLoad = new OssClient();
        myUpLoad
          .multipartUpload({
            file: file.raw,
            progress: function*(p) {},
          })
          .then((data) => {
            this.fileList.push({ ...file, url: data.url });
            let _fileList = this.fileList;
            let _fileListUrls = [];
            for (let i = 0; i < _fileList.length; i++) {
              _fileListUrls.push(_fileList[i].url);
            }
            this.form.pictureList = _fileListUrls;
          });
      }
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleRemove(file, fileList) {
      const uid = file.uid;
      this.fileList.forEach((item, index, arr) => {
        if (item.uid === uid) arr.splice(index, 1);
      });
    },
    commit() {
      let form = this.form;
      if (form.flowNum == "") {
        this.$message.warning("请填写流水号");
        return;
      }
      if (form.pictureList.length == 0) {
        this.$message.warning("请上传转账截图");
        return;
      }
      let params = {
        billId: this.billId,
        offlineChannel: this.form.paymentMethods,
        flowNo: this.form.flowNum,
        paymentVoucher: form.pictureList.toString(),
      };
      consumer_credit_createRefundPayment(params).then((res) => {
        this.$message.success("提交成功");
        setTimeout(() => {
          this.$router.push({
            name: "admin-finance-credit",
          });
        }, 1000);
      });
    },
    // 选择结算方式
    onChangeRadio(value) {
      this.form.paymentMethods = value;
      this.req_detail();
    },
  },
  watch: {
    fileList: {
      handler(val) {
        this.form.pictureList = val.map((item) => item.url);
      },
      deep: true,
    },
  },
  created() {
    this.req_detail();
  },
};
